import MessageMap from '../i18n.d';

const en: MessageMap = {
  PromotionPage: {
    PAGE_LAYOUT_TITLE: 'Promotions',
    NEW_PROMO: 'NEW PROMOTION',
    ZONE_SELECTOR_COUNTRY_LABEL: 'Country:',
    INVALID_COUNTRY: 'Invalid Country',
  },
  UploadImageHeaderTableButton: {
    ERROR: 'Update image request failed',
  },
  UploadImageHeaderTableButtonView: {
    ERROR: 'Image size limit exceeded. Please, upload an image no larger than 1MB.',
  },
  DownloadCsvPlpView: {
    BUTTON_LABEL: 'DOWNLOAD CSV',
    DOWNLOADING_CSV_LABEL: 'Downloading',
    LOADING_SUBTITLE:
      'Wait until your file is ready, or you can close this window and consult the download file in',
    HREF: 'Download Page.',
  },
  AttributesDownloadModalUseCase: {
    ERROR_LOAD_TOP_CATEGORIES: 'Unable to load attributes',
    PROCESSING_DOWNLOAD: 'Processing Download',
    DOWNLOAD_REQUEST_FAILED: 'Download Request failed',
  },
  LoadPromotionUseCase: {
    SEARCH_FAILED: 'Search Failed',
  },
  ModalExportCsvView: {
    CANCEL_BUTTON: 'CANCEL',
    DOWNLOAD_BUTTON: 'DOWNLOAD',
    MODAL_TITLE: 'Download',
    COUNT_PROMOTIONS: 'Promotions in queue',
    COUNT_PROMOTION: 'Promotion in queue',
    SELECT_FIELDS: 'Select the fields',
    EMPTY_PROMOTIONS_ERROR: 'You must have at least one promotions in the queue.',
    MAX_PROMOTIONS_ERROR: 'Amount of more than 2000 promotions exceeded.',
  },
  ItemsModalError: {
    ERROR_LOAD_ITEMS_TITLE: 'We could not load this combo',
    NO_LONGER_VALID: 'It looks like the item associated to this combo is no longer valid.',
    PLEASE_CONTACT: 'Please,',
    CONTACT_HREF: 'contact our support team.',
    CONFIRM_BUTTON: 'Got it',
  },
  DDCModalError: {
    ERROR_LOAD_ITEMS_TITLE: 'Ops...',
    COULD_NOT_LOAD: 'Could not load this combo.',
    NO_LONGER_VALID: 'It looks like the DDC associated to this combo is no longer valid.',
    PLEASE_CONTACT: 'Please,',
    CONTACT_HREF: 'contact support.',
    CONFIRM_BUTTON: 'Close',
  },
  PromotionsTable: {
    HEADER_COLUMN_TITLE: 'Title',
    HEADER_COLUMN_STATUS: 'Status',
    HEADER_COLUMN_RELEVANCE: 'Featured Promotion',
  },
  ModalExportCsv: {
    DESCRIPTION: 'Description',
  },
  CheckboxAttributesView: {
    ALL_FIELDS: 'All fields',
  },
  CreatePromotionRoute: {
    TITLE: 'New DT Combo',
    ALERT_MESSAGE: 'To activate the combo, go to Membership and search: ID',
    COMBO_VENDOR_ID_COPY: 'Id copied!',
  },
  NewPromotionDiscardButtonView: {
    LABEL: 'Discard',
  },
  NewPromotionCreateButtonView: {
    LABEL: 'Create',
  },
  PromotionInformationCard: {
    COMBO_INFORMATION_CARD_TITLE: 'Combo Information',
    SUPPLIER_LABEL: 'Supplier',
    COMBO_ID: 'ID',
    TITLE_LABEL: 'Title',
    TYPE_LABEL: 'Type',
    COMBO_TYPE: 'Digital Trade 3P',
    DESCRIPTION_LABEL: 'Description',
    FEATURED_LABEL: 'Featured Promotion',
    FEATURED_ON: 'On',
    FEATURED_OFF: 'Off',
    ITEMS_DAILY_LIMIT: 'Daily Limit',
    ITEMS_MONTHLY_LIMIT: 'Monthly Limit',
    COMBO_ID_TOOLTIP_MESSAGE: "The promotion's identification number",
    COMBO_TYPE_TOOLTIP_MESSAGE: 'The type of the promotion',
    COMBO_DESCRIPTION_TOOLTIP_MESSAGE: 'This is the description of the combo',
    COPY_URL_TOOLTIP: 'Combo Id copied!',
    EDIT: 'Edit',
    FEATURED_PROMOTION: 'Featured Promotion',
  },
  PromotionCreatedDateView: {
    INVALID_DATE_ERROR: 'Invalid date',
    MIN_START_DATE_ERROR: 'Start date should be after current date',
    MAX_START_DATE_ERROR_WITH_END_DATE: 'Start date should not be after end date',
    MIN_END_DATE_ERROR_WITH_START_DATE: 'End date should not be before start date',
    MIN_END_DATE_ERROR: 'End date should be after current date',
    START_DATE: 'Start date',
    END_DATE: 'Expiration date',
  },
  DistributionCenterView: {
    TITLE: 'Distribution Centers',
    BUTTON_TITLE: 'ADD',
  },
  DdcRowView: {
    BUTTON: 'Remove',
  },
  DdcListView: {
    NO_DDC: 'The DCs you add will be displayed here',
    NO_DDC_MODAL: 'There is no DDC available to select',
    DDC_ID: 'ID',
    DDC_NAME: 'Name',
    DDC_STATUS: 'Status',
    DDC_ENABLED: 'Enabled',
    DDC_DISABLED: 'Disabled',
    DDC_SERVICE_MODEL: 'Service Model',
  },
  ItemsView: {
    BUTTON_TITLE: 'Add',
    TITLE: 'Items',
    NO_ITEMS: 'The items you add will be displayed here',
  },
  AddDistributionCenterModal: {
    TITLE: 'Distribution Centers',
    SUB_TITLE: 'Add the DCs related to the combo.',
    BUTTON_SUBMIT_TITLE: 'Add',
    BUTTON_CANCEL_TITLE: 'Cancel',
    INPUT_PLACEHORDER: 'Search by DC name',
  },
  TableSearchModalView: {
    ITEM_SELECTED: 'item selected.',
    ITEMS_SELECTED: 'items selected.',
  },
  AddItemModalView: {
    TITLE_HEADER: 'Items',
    LABEL_SUBMIT_BUTTON: 'Add',
    LABEL_CANCEL_BUTTON: 'Cancel',
    SEARCH_PLACEHOLDER: 'Search by SKU or Product Title',
    NO_ITEM_AVAILABLE: 'The items you add will be displayed here.',
  },
  PromotionImageUploaderView: {
    TITLE: 'Promotion Image',
    COPY_PICTURE_URL: 'Copy Picture URL',
    CANCEL_BUTTON: 'Cancel',
    SAVE_BUTTON: 'Save',
    COPY_PICTURE_URL_TOOLTIP: 'Picture URL copied!',
  },
  PromotionImageUploader: {
    IMAGE_FORMAT_NOT_SUPPORTED:
      'Image format not supported. Please, upload an image in .png or .jpeg formats only',
    IMAGE_SIZE_NOT_SUPPORTED:
      'Image size limit exceeded. Please, upload an image no larger than 1MB.',
    UPLOAD_MAIN_PROMOTION_IMAGE: 'Upload the combo image',
    THUMBNAILS_MUST_JPG_PNG_FORMAT: 'Thumbnails must be JPG, PNG format and must be less than 1MB',
    EDIT_LABEL_BUTTON: 'EDIT',
  },
  FormikInputTextView: {
    REQUIRED: 'Required',
  },
  UpdatePromotionStatusModalView: {
    ENABLED_STATUS_LABEL: 'Enabled',
    DISABLED_STATUS_LABEL: 'Disabled',
    SAVE_BUTTON_LABEL: 'Save',
    CANCEL_BUTTON_LABEL: 'Cancel',
  },
  NewPromoModalView: {
    PROMOTION_TYPE_TITLE: 'Choose promotion type',
    SUPPLIER_SELECTOR_TITLE: 'Select the supplier',
    COUNTRY_SELECTOR_TITLE: 'Select country',
    MODAL_TITLE: 'New Promotion',
    BUSINESS_MODEL: 'Service model',
    BUSINESS_MODEL_DESCRIPTION: 'Select the service model of the promotion you are creating.',
    REQUIRED_PROPERTY: 'The property {property} is required',
    REQUIRED_FIELD: 'This field is required',
    CANCEL_BUTTON: 'Cancel',
    SEND_BUTTON: 'Get started',
    NO_DDC_MESSAGE: 'There is no distribution center available to be selected for your vendor',
    NO_ITEMS_MESSAGE: 'There is no product available to be selected for your vendor',
  },
  PromotionCards: {
    PROMOTION_TYPE_DTC: 'Digital Trade Combos',
    PROMOTION_TYPE_DTC_DESCRIPTION: 'Combos to be redeemed by customers using reward points',
  },
  BusinessCards: {
    MODEL_1P3L: '1P3L',
    MODEL_1P3L_DESCRIPTION: 'Sold by ABI and delivered by supplier',
  },
  PromotionSearchView: {
    SEARCH_PROMOTION: 'Search By Promotion ID or Title',
  },
  SearchPromotionButton: {
    SEARCH: 'SEARCH',
  },
  PromotionFilters: {
    BUTTON: 'Filters',
    FILTER: 'Filters',
    PROMOTION_TYPE_FILTER: 'Type',
    COUNTRY_FILTER: 'Country',
    APPLY_BUTTON: 'Apply',
    CANCEL_BUTTON: 'Cancel',
  },
  TypeFilter: {
    DT3P: 'Digital Trade 3P (DT3P)',
    DT: 'Digital Trade (DT)',
    FG: 'Free Goods (FG)',
    D: 'Discounts (D)',
  },
  PromotionDetailsRoute: {
    COPY_TITLE_TOOLTIP: 'Combo Id copied!',
    PAGE_BREADCRUMB_DETAILS: 'Details',
    PAGE_BREADCRUMB_PROMOTIONS: 'Promotions',
    IMAGE_UPDATED_SUCCEED: 'Image Updated Succeed',
    IMAGE_UPDATED_FAILED: 'Image Updated Failed',
    INFO_UPDATED_SUCCEED: 'Promotion Updated Succeed',
    INFO_UPDATED_FAILED: 'Promotion Updated Failed',
    STATUS_UPDATED_SUCCEED: 'Status Updated Succeed',
    STATUS_UPDATED_FAILED: 'Status Updated Failed',
  },
  UpdateBulkPromotionsStatusUseCase: {
    SUCCESS_NOTIFICATION: 'Update Succeed',
    FAILED_NOTIFICATION: 'Update Failed',
    ERROR_NOTIFICATION: 'Update Error',
  },
  EditPromotionInformationCard: {
    COMBO_INFORMATION_CARD_TITLE: 'Combo Information',
    REQUIRED_PROPERTY: 'The property {property} is required',
    REQUIRED_FIELD: 'This field is required',
    SAVE: 'Save',
    CANCEL: 'Cancel',
  },
  PromotionTable: {
    HEADER_COLUMN_TITLE: 'Title',
    HEADER_COLUMN_PROMOID: 'Promo ID',
    HEADER_COLUMN_STATUS: 'Status',
    HEADER_COLUMN_COMBOTYPE: 'Type',
    HEADER_COLUMN_ENDDATE: 'End Date',
    TOOLTIP_COLUMN_PROMOID: "The promotion's identification number",
    TOOLTIP_COLUMN_COMBOTYPE: 'Type of promotion',
    TOOLTIP_COLUMN_ENDDATE: 'The date when the promotion ends',
    TOOLTIP_COPY_TEXT: 'Promo ID was copied!',
    UPLOAD_IMAGE: 'Upload Image',
    EMPTY_TABLE_TITLE: 'No results so far',
    EMPTY_TABLE_DESCRIPTION:
      'Try changing the search terms or clearing your filters for more results.',
  },
  StatusTableCellView: {
    ENABLED: 'Enabled',
    DISABLED: 'Disabled',
  },
  Pagination: {
    LABEL: 'Rows per page',
  },
  Navigation: {
    PROMOTIONS: 'Promotions',
    BULKEDITOR: 'Bulk Editor',
  },
  FilterChipList: {
    PROMOTION_TYPE_FILTER: 'Type',
  },
  Countries: {
    AR: 'Argentina',
    BR: 'Brazil',
    CA: 'Canada',
    CO: 'Colombia',
    DO: 'Dominican Republic',
    EC: 'Ecuador',
    HN: 'Honduras',
    MX: 'Mexico',
    PA: 'Panama',
    PE: 'Peru',
    PY: 'Paraguai',
    SV: 'El Salvador',
    TZ: 'Tanzania',
    UY: 'Uruguay',
    ZA: 'South Africa',
    UG: 'Uganda',
  },
  PromotionCardView: {
    QUANTITY: 'Quantity',
  },
  UploadListView: {
    TITLE: 'Reports',
  },
  DownloadCsvUseCase: {
    SUCCESS: 'File generation completed',
    ERROR: 'Error generating file. Check error reason on the Download History',
  },
  UpdateBulkPromotionImageUseCase: {
    SUCCESS_NOTIFICATION: 'Promotion Image updated',
    FAILED_NOTIFICATION: 'Promotion Failed to update',
    ERROR_NOTIFICATION: 'Update Error',
  },
  BulkEditorPage: {
    TITLE: 'Bulk Editor',
    UPLOADS_TAB: 'Uploads',
    DOWNLOADS_TAB: 'Downloads',
    GET_UPLOADS_ERROR: 'Failed to Get Uploads Reports',
    GET_DOWNLOADS_ERROR: 'Failed to Get Downloads History',
    IMPORT_REQUEST_FAILED: 'Import Request Failed',
  },
  DownloadListView: {
    TITLE: 'History',
  },
  CSVTable: {
    FAILED_MESSAGE: 'Failed',
    HEADER_COLUMN_DATE: 'Date',
    HEADER_COLUMN_STATUS: 'Status',
    HEADER_COLUMN_DESCRIPTION: 'Error Description',
    HEADER_COLUMN_UPLOADDESCRIPTION: 'File Description',
    HEADER_COLUMN_TOTALROWS: 'Total rows',
    HEADER_COLUMN_FAILEDROWS: 'Failed rows',
    STATUS_FAILED: 'Failed',
    STATUS_FAILED_ROWS: 'failed rows',

    STATUS_DONE: 'Done',
    STATUS_PROCESSING: 'Processing',
    STATUS_COMPLETED: 'Completed',
    STATUS_PARTIALLY_SUCCESSFUL: 'failed rows',
    EMPTY_LIST: 'There are no downloads to be displayed.',
    HEADER_COLUMN_DOWNLOAD: 'Action',
    HEADER_COLUMN_COUNTRY: 'Country',
  },
  CsvTableView: {
    EMPTY_DOWNLOAD_LIST: 'There are no downloads to be displayed.',
    EMPTY_UPLOAD_LIST: 'There are no uploads to be displayed.',
  },
  UploadFileButtonView: {
    LABEL: 'Upload file',
  },
  UploadCsvModalView: {
    TITLE: 'Upload file',
    PRODUCTS_LIMIT_WARNING: 'We have a limit of 2,000 products per upload.',
    DRAG_AND_DROP_MESSAGE: 'Drag and drop the CSV file here or',
    SELECT_FILE_BUTTON: 'Select file',
    DESCRIPTION: 'File description',
    BUTTON_CANCEL: 'Cancel',
    BUTTON_UPLOAD: 'Upload',
    ONLY_CSV_SUPPORT: 'We only support CSV files.',
    COUNTRIES_LABEL: 'Country',
  },
  UpdateStatusTableHeaderView: {
    PLACEHOLDER: 'Status',
    ENABLED: 'Enabled',
    DISABLED: 'Disabled',
    CANCEL_BUTTON_LABEL: 'Cancel',
    SAVE_BUTTON_LABEL: 'Save',
  },
  PromotionTablePagination: {
    ROWS_PER_PAGE_LABEL: 'Rows per page',
  },
  LoadingModalView: {
    TITLE: 'Almost there...',
    SUBTITLE: "Just a few more seconds and we're good to go.",
    CLOSE_BUTTON: 'Close',
  },
  LoadPromotionDetailsUseCase: {
    UNABLE_LOAD_PROMOTION_DETAILS: 'Promotion not found',
  },
  ErrorModalView: {
    TITLE: 'Ops..',
  },
};

export default en;
