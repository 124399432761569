import {
  createGenerateClassName,
  CssBaseline,
  StylesProvider,
  ThemeProvider,
} from '@material-ui/core';
import {
  setAppHeaderConfig,
  useAnalyticsService,
  useAuthenticationService,
  useFeatureToggleService,
} from 'admin-portal-shared-services';
import React from 'react';
import * as Grid from '@admin-portal-shared-components/grid';
import { ToastContainer } from 'react-toastify';
import Router from './Router';
import theme from './themes/theme';
import { EnvProvider } from './provider';
import { EnvConfig } from './provider/EnvProvider.types';
import 'react-toastify/dist/ReactToastify.css';
import useUserCountries from './hooks/useUserCountries/useUserCountries';

interface EnvProps extends EnvConfig {
  PROMOTIONS_SEGMENT_KEY: string | undefined;
}

const generateClassName = createGenerateClassName({
  productionPrefix: 'admin-portal-promotions-beta-mfe',
  seed: 'admin-portal-promotions-beta-mfe',
});

function App({
  PROMOTIONS_SEGMENT_KEY,
  CMS_HOST,
  CMS_HOST_EUROPE,
  EUROPE_COUNTRIES,
}: EnvProps): JSX.Element {
  const segmentKey = PROMOTIONS_SEGMENT_KEY ?? 'RCiOmw5COuY6qVKP4A4kI8wIA747wgrP';
  const jwtDecoded = useAuthenticationService().parseJwt();
  const analyticsService = useAnalyticsService();
  analyticsService.load(segmentKey);
  useFeatureToggleService().loadToggles();

  localStorage.setItem('EUROPE_COUNTRIES', EUROPE_COUNTRIES);
  localStorage.setItem('CMS_HOST', CMS_HOST);
  localStorage.setItem('CMS_HOST_EUROPE', CMS_HOST_EUROPE);

  const traits = {
    country: jwtDecoded?.country,
    email: jwtDecoded?.email,
    first_name: jwtDecoded?.given_name,
    last_name: jwtDecoded?.family_name,
  };

  analyticsService.identify(traits);

  const userCountries = useUserCountries();

  React.useEffect(() => {
    setAppHeaderConfig({
      countryOptions: userCountries,
      countrySelect: true,
      vendorSelect: true,
    });
  }, [userCountries]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <StylesProvider generateClassName={generateClassName}>
        <EnvProvider env={{ CMS_HOST, CMS_HOST_EUROPE, EUROPE_COUNTRIES }}>
          <Grid.Container
            style={{
              rowGap: '1rem',
            }}
            type="fluid"
            sidebar
          >
            <ToastContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar
              newestOnTop
              closeOnClick
              rtl={false}
              draggable
              pauseOnHover
              theme="colored"
            />
            <Router />
          </Grid.Container>
        </EnvProvider>
      </StylesProvider>
    </ThemeProvider>
  );
}
export default App;
