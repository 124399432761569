export const PLP_DOWNLOAD_CSV = 'promotions-plp-download-csv';
export const BULK_EDITOR_SIDE_MENU = 'promotions-bulk-editor-side-menu';
export const PLP_BULK_EDITOR = 'promotions-plp-bulk-editor';
export const BULK_COLUMN_COUNTRY = 'promotions-bulk-column-country';
export const VENDOR_1P_3L = 'promotions-vendor-1P-3L';
export const UPLOAD_DOWNLOAD_LIST = 'promotions-upload-download-list';
export const ALLOW_CREATION: { [key: string]: string } = {
  AR: 'promotions-allow-creation-ar',
  BR: 'promotions-allow-creation-br',
  CO: 'promotions-allow-creation-co',
  DO: 'promotions-allow-creation-do',
  EC: 'promotions-allow-creation-ec',
  HN: 'promotions-allow-creation-hn',
  MX: 'promotions-allow-creation-mx',
  PA: 'promotions-allow-creation-pa',
  PE: 'promotions-allow-creation-pe',
  PY: 'promotions-allow-creation-py',
  SV: 'promotions-allow-creation-sv',
  UY: 'promotions-allow-creation-uy',
  ZA: 'promotions-allow-creation-za',
  BE: 'promotions-allow-creation-be',
  DE: 'promotions-allow-creation-de',
  GB: 'promotions-allow-creation-gb',
  NL: 'promotions-allow-creation-nl',
};
export const PIM_SIDE_MENU = 'pim-side-menu';
export const PROMOTIONS_SERVICE_MODEL = 'promotions-service-model';
