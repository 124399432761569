import { createIntl, createIntlCache, defineMessages, IntlShape } from 'react-intl';
import MessageMap from './i18n.d';
import en from './languages/en';
import ptBR from './languages/pt-BR';
import es419 from './languages/es-419';

interface FlatMessageMap {
  [key: string]: string;
}

const MESSAGES: { [language: string]: MessageMap } = {
  'en-US': en,
  'pt-BR': ptBR,
  'es-419': es419,
};
export const DEFAULT_LANGUAGE = 'en-US';

const flattenMessages = (nestedMessages: MessageMap): FlatMessageMap =>
  Object.entries(nestedMessages).reduce(
    (messages, [messageKey, message]) =>
      Object.assign(
        messages,
        Object.fromEntries(
          Object.entries(message).map(([key, value]) => [`${messageKey}.${key}`, value])
        )
      ),
    {}
  );

const validateSelectedLanguage = (language: string) => {
  if (!MESSAGES[language]) {
    return DEFAULT_LANGUAGE;
  }
  return language;
};

const defaultMessageMap = flattenMessages(MESSAGES[DEFAULT_LANGUAGE]);

const fillFlattenedMessaged = (messageMap: FlatMessageMap): FlatMessageMap => ({
  ...defaultMessageMap,
  ...messageMap,
});

const intlProvider = (locale: string): IntlShape => {
  const cache = createIntlCache();
  const language = validateSelectedLanguage(locale);
  return createIntl(
    {
      locale: language,
      messages: defineMessages(fillFlattenedMessaged(flattenMessages(MESSAGES[language]))),
    },
    cache
  );
};

/* eslint-disable @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any */
export const flattenObject = (ob: any): any => {
  const toReturn: { [key: string]: any } = {};

  for (const i in ob) {
    if (typeof ob[i] === 'object' && ob[i] !== null) {
      const flatObject = flattenObject(ob[i]);
      // eslint-disable-next-line guard-for-in
      for (const x in flatObject) {
        toReturn[`${i}.${x}`] = flatObject[x];
      }
    } else {
      toReturn[i] = ob[i];
    }
  }
  return toReturn;
};
/* eslint-enable */

export default intlProvider;
