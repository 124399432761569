export const PROMOTION_APP_TIER_BASE_URL = '/api/combo-partner-service';
export const ADMIN_PORTAL_SHARED_BFF_URL = '/api/v1/admin-portal-shared-bff';
export const PROMOTION_COMBO_FILE_URL = '/api/combos-partner-bulk-editor/combos';

export const PROMOTION_APP_TIER_BASE_URL_EUROPE = '/api/eu/combo-partner-service';
export const PROMOTION_COMBO_FILE_URL_EUROPE = '/api/eu/combos-partner-bulk-editor/combos';

export const PAGINATION_SIZE = 25;
export const DEFAULT_PAGINATION_SIZE = 50;
export const DEFAULT_CURRENT_PAGE = 0;
export const APP_NAME = 'PROMOTIONS';
export enum AuditLogEntities {
  ORDERS = 'ORDERS',
  ACCOUNTS = 'ACCOUNTS',
  TICKETS = 'TICKETS',
  CATEGORIES = 'CATEGORIES',
  DDC = 'DDC',
  INVOICES = 'INVOICES',
  ITEMS = 'ITEMS',
  PRODUCTS = 'PRODUCTS',
  REWARDS = 'REWARDS',
  USERS = 'USERS',
  VENDORS = 'VENDORS',
  PRICES = 'PRICES',
  PROMOTIONS = 'REWARDS', //TODO: Change to correct value when BeesHub team create it
}

export enum AuditLogOperations {
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
  INSERT = 'INSERT',
  VIEW = 'VIEW',
}
