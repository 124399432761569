import React from 'react';
import LoadingModalView from './LoadingModalView';

interface Props {
  title?: string;
  subtitle?: string | JSX.Element;
  hasCloseButton?: boolean;
  onClose?: () => void;
}

function LoadingModal({
  title,
  subtitle,
  hasCloseButton,
  onClose = () => null,
}: Props): JSX.Element {
  const [isOpenModal, setIsOpenModal] = React.useState(true);

  const handleClose = () => {
    setIsOpenModal(false);
    onClose();
  };

  return (
    <LoadingModalView
      title={title}
      subtitle={subtitle}
      hasCloseButton={hasCloseButton}
      onClose={handleClose}
      isOpen={isOpenModal}
    />
  );
}

export default LoadingModal;
