import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(3),
      flex: 1,
    },
    containerBody: {
      display: 'flex',
      position: 'relative',
      zIndex: 1,
      width: '100%',
    },
  })
);

export default useStyles;
