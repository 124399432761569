import { createTheme } from '@material-ui/core/styles';
import palette from './palette';

const theme = createTheme({
  palette,
  typography: {
    fontFamily: 'Work Sans, sans-serif',
    h1: {
      color: palette.text.primary,
      fontWeight: 500,
      fontSize: '35px',
      letterSpacing: '-0.24px',
      lineHeight: '40px',
    },
    h2: {
      color: palette.text.primary,
      fontWeight: 500,
      fontSize: '29px',
      letterSpacing: '-0.24px',
      lineHeight: '32px',
    },
    h3: {
      color: palette.text.primary,
      fontWeight: 600,
      fontSize: '24px',
      letterSpacing: '0px',
      lineHeight: '28px',
    },
    h4: {
      color: palette.text.primary,
      fontWeight: 500,
      fontSize: '20px',
      letterSpacing: '-0.06px',
      lineHeight: '24px',
    },
    h5: {
      color: palette.text.primary,
      fontWeight: 500,
      fontSize: '16px',
      letterSpacing: '-0.05px',
      lineHeight: '20px',
    },
    h6: {
      color: palette.text.primary,
      fontWeight: 500,
      fontSize: '14px',
      letterSpacing: '-0.05px',
      lineHeight: '20px',
    },
    subtitle1: {
      color: palette.text.primary,
      fontSize: '16px',
      letterSpacing: '-0.05px',
      lineHeight: '25px',
    },
    subtitle2: {
      color: palette.text.secondary,
      fontWeight: 400,
      fontSize: '14px',
      letterSpacing: '-0.05px',
      lineHeight: '21px',
    },
    body1: {
      color: palette.text.primary,
      fontSize: '16px',
      letterSpacing: '-0.05px',
      lineHeight: '21px',
    },
    body2: {
      color: palette.text.secondary,
      fontSize: '14px',
      letterSpacing: '-0.04px',
      lineHeight: '21px',
    },
    button: {
      color: palette.text.primary,
      fontSize: '16px',
    },
    caption: {
      color: palette.text.secondary,
      fontSize: '11px',
      letterSpacing: '0.33px',
      lineHeight: '13px',
    },
    overline: {
      color: palette.text.secondary,
      fontSize: '11px',
      fontWeight: 500,
      letterSpacing: '0.33px',
      lineHeight: '13px',
      textTransform: 'uppercase',
    },
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        body: {
          color: 'rgba(0, 0, 0, 0.87)',
          lineHeight: '1.43',
          letterSpacing: '0.01071em',
          backgroundColor: '#fafafa',
          fontSize: '0.875rem',

          '&::backdrop': {
            backgroundColor: '#fafafa',
          },
        },
      },
    },
    MuiSwitch: {
      root: {
        '& [class*="MuiSwitch-track"]': {
          backgroundColor: '#DFDEDE',
          opacity: 1,
        },
        '& [class*="MuiSwitch-thumb"]': {
          backgroundColor: '#fff',
          boxShadow: '0 1px 2px 0 rgba(0,0,0,0.5)',
        },
        '& .Mui-checked': {
          '& [class*="MuiSwitch-thumb"]': {
            backgroundColor: '#99ACEA',
          },
          '& + [class*="MuiSwitch-track"]': {
            backgroundColor: '#4D6EDB !important',
            opacity: '1 !important',
          },
        },
      },
    },
  },
});

export default theme;
