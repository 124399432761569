import MessageMap from '../i18n.d';

const ptBR: MessageMap = {
  PromotionPage: {
    PAGE_LAYOUT_TITLE: 'Promoções',
    NEW_PROMO: 'NOVA PROMOÇÃO',
    ZONE_SELECTOR_COUNTRY_LABEL: 'País:',
  },
  CreatePromotionRoute: {
    TITLE: 'Novo Combo DT',
    ALERT_MESSAGE: 'To activate the combo, go to Membership and search: ID', // TBD
    COMBO_VENDOR_ID_COPY: 'Id copied!', // TBD
  },
  NewPromotionDiscardButtonView: {
    LABEL: 'Excluir',
  },
  NewPromotionCreateButtonView: {
    LABEL: 'Criar',
  },
  PromotionInformationCard: {
    COMBO_INFORMATION_CARD_TITLE: 'Informação do combo',
    SUPPLIER_LABEL: 'Fornecedor',
    COMBO_ID: 'Identidade',
    TITLE_LABEL: 'Título',
    TYPE_LABEL: 'Tipo',
    DESCRIPTION_LABEL: 'Descrição',
    FEATURED_LABEL: ' Promoção em destaque',
    ITEMS_DAILY_LIMIT: 'Limite diário',
    ITEMS_MONTHLY_LIMIT: 'Limite mensal',
    COMBO_ID_TOOLTIP_MESSAGE: 'Número de identificação da promoção',
    COMBO_TYPE_TOOLTIP_MESSAGE: 'Tipo de promoção',
    COMBO_DESCRIPTION_TOOLTIP_MESSAGE: 'This is the description of the combo',
    COPY_URL_TOOLTIP: 'Combo Id copied!',
    EDIT: 'Editar',
    FEATURED_PROMOTION: ' Promoção em destaque',
  },
  PromotionCreatedDateView: {
    INVALID_DATE_ERROR: 'Data inválida',
    MIN_START_DATE_ERROR: 'A data de início deve ser posterior à data atual',
    MAX_START_DATE_ERROR_WITH_END_DATE: 'A data de início não deve ser posterior à data de término',
    MIN_END_DATE_ERROR_WITH_START_DATE: 'A data de término não deve ser anterior à data de início',
    MIN_END_DATE_ERROR: 'A data de término deve ser posterior à data atual',
    START_DATE: 'Data inicial',
    END_DATE: 'Data final',
  },
  DistributionCenterView: {
    TITLE: 'Centros de distribuição',
    BUTTON_TITLE: 'Adicionar',
  },
  DdcRowView: {
    BUTTON: 'Remover',
  },
  DdcListView: {
    NO_DDC: 'Os CDDs adicionados por você serão exibidos aqui.',
    NO_DDC_MODAL: 'Não há nenhum CDD disponível para selecionar.',
    DDC_ID: 'ID',
    DDC_NAME: 'Nome',
    DDC_STATUS: 'Status',
    DDC_ENABLED: 'Habilitado',
    DDC_DISABLED: 'Desabilitado',
    DDC_SERVICE_MODEL: 'Modelo de Serviço',
  },
  ItemsView: {
    BUTTON_TITLE: 'Adicionar',
    TITLE: 'Itens',
    NO_ITEMS: 'Os itens adicionados serão exibidos aqui.',
  },
  AddDistributionCenterModal: {
    TITLE: 'Centros de distribuição',
    SUB_TITLE: 'Adicionar os CDDs relacionados ao combo.',
    BUTTON_SUBMIT_TITLE: 'Adicionar',
    BUTTON_CANCEL_TITLE: 'Cancelar',
    INPUT_PLACEHORDER: 'Buscar pelo nome do CDD',
  },
  TableSearchModalView: {
    ITEM_SELECTED: 'item selecionado.',
    ITEMS_SELECTED: 'itens selecionados.',
  },
  AddItemModalView: {
    TITLE_HEADER: 'Itens',
    LABEL_SUBMIT_BUTTON: 'Adicionar',
    LABEL_CANCEL_BUTTON: 'Cancelar',
    NO_ITEM_AVAILABLE: 'Os itens adicionados serão exibidos aqui.',
  },
  ItemsModalError: {
    ERROR_LOAD_ITEMS_TITLE: 'Não foi possível carregar este combo',
    NO_LONGER_VALID: 'O produto associado a este combo não é mais válido.',
    PLEASE_CONTACT: 'Por favor, entre em contato com o',
    CONTACT_HREF: 'nosso suporte.',
    CONFIRM_BUTTON: 'OK',
  },
  PromotionTable: {
    HEADER_COLUMN_TITLE: 'Título',
    HEADER_COLUMN_PROMOID: 'Promo ID',
    HEADER_COLUMN_STATUS: 'Status',
    HEADER_COLUMN_COMBOTYPE: 'Tipo',
    HEADER_COLUMN_ENDDATE: 'Data final',
    TOOLTIP_COLUMN_PROMOID: 'Número de identificação da promoção',
    TOOLTIP_COLUMN_COMBOTYPE: 'Tipo de promoção',
    TOOLTIP_COLUMN_ENDDATE: 'Data do final da promoção',
    EMPTY_TABLE_TITLE: 'No results so far',
    EMPTY_TABLE_DESCRIPTION:
      'Try changing the search terms or clearing your filters for more results.',
  },
  PromotionImageUploader: {
    IMAGE_FORMAT_NOT_SUPPORTED:
      'Image format not supported. Please, upload an image in .png or .jpeg formats only',
    IMAGE_SIZE_NOT_SUPPORTED: 'Sua imagem deve estar no formato JPG ou PNG e ter menos de 1MB.',
    UPLOAD_MAIN_PROMOTION_IMAGE: 'Carregar a imagem do combo.',
    THUMBNAILS_MUST_JPG_PNG_FORMAT:
      'Sua imagem deve estar no formato JPG ou PNG e ter menos de 1MB.',
    EDIT_LABEL_BUTTON: 'Editar',
  },
  FormikInputTextView: {
    REQUIRED: 'Obrigatório',
  },
  UpdatePromotionStatusModalView: {
    ENABLED_STATUS_LABEL: 'Habilitado',
    DISABLED_STATUS_LABEL: 'Desabilitado',
    SAVE_BUTTON_LABEL: 'Salvar',
    CANCEL_BUTTON_LABEL: 'Cancelar',
  },
  NewPromoModalView: {
    PROMOTION_TYPE_TITLE: 'Escolher o tipo de promoção',
    SUPPLIER_SELECTOR_TITLE: 'Selecionar o fornecedor',
    COUNTRY_SELECTOR_TITLE: 'Selecionar o país',
    MODAL_TITLE: 'Nova Promoção',
    BUSINESS_MODEL: 'Modelo de negócio',
    BUSINESS_MODEL_DESCRIPTION: 'Selecione o modelo de negócio ideal para você.',
    REQUIRED_PROPERTY: 'The property {property} is required',
    REQUIRED_FIELD: 'O preenchimento deste campo é obrigatório.',
    CANCEL_BUTTON: 'Cancelar',
    SEND_BUTTON: 'Começar',
    NO_DDC_MESSAGE:
      'Não há centros de distribuição disponíveis para serem selecionados para seu Vendor',
    NO_ITEMS_MESSAGE: 'Não há produtos disponíveis para serem selecionados para seu Vendor',
  },
  PromotionCards: {
    PROMOTION_TYPE_DTC: 'Digital Trade Combos', // TBD
    PROMOTION_TYPE_DTC_DESCRIPTION: 'Combos a serem resgatados por clientes usando seus pontos.',
  },
  BusinessCards: {
    MODEL_1P3L: '1P3L',
    MODEL_1P3L_DESCRIPTION: 'Vendido pela ABI e entregue por um fornecedor.',
  },
  PromotionSearchView: {
    SEARCH_PROMOTION: 'Buscar por ID da promoção e título',
  },
  SearchPromotionButton: {
    SEARCH: 'Buscar',
  },
  StatusTableCellView: {
    ENABLED: 'Habilitado',
    DISABLED: 'Desabilitado',
  },
  Navigation: {
    PROMOTIONS: 'Promoções',
    BULKEDITOR: 'Edição em grupo',
  },
  EditPromotionInformationCard: {
    COMBO_INFORMATION_CARD_TITLE: 'Informação do combo',
    SAVE: 'Salvar',
    CANCEL: 'Cancelar',
  },
  PromotionImageUploaderView: {
    TITLE: 'Imagem da promoção',
    COPY_PICTURE_URL: 'Copiar a URL da imagem',
    CANCEL_BUTTON: 'Cancelar',
    SAVE_BUTTON: 'Salvar',
    COPY_PICTURE_URL_TOOLTIP: 'URL da imagem copiada!',
  },
  PromotionDetailsRoute: {
    PAGE_BREADCRUMB_DETAILS: 'Detalhes',
    PAGE_BREADCRUMB_PROMOTIONS: 'Promoções',
  },
  PromotionCardView: {
    QUANTITY: 'Quantidade',
  },
  UploadListView: {
    TITLE: 'Reports',
  },
  BulkEditorPage: {
    TITLE: 'Edição em grupo',
    UPLOADS_TAB: 'Uploads',
    DOWNLOADS_TAB: 'Downloads',
    GET_UPLOADS_ERROR: 'Failed to Get Uploads Reports',
    GET_DOWNLOADS_ERROR: 'Failed to Get Downloads History',
    IMPORT_REQUEST_FAILED: 'Import Request Failed',
  },
  DownloadListView: {
    TITLE: 'Histórico',
  },
  CSVTable: {
    FAILED_MESSAGE: 'Falha',
    HEADER_COLUMN_DATE: 'Data',
    HEADER_COLUMN_STATUS: 'Status',
    HEADER_COLUMN_DESCRIPTION: 'Descrição do erro',
    HEADER_COLUMN_UPLOADDESCRIPTION: 'Descrição do arquivo',
    HEADER_COLUMN_TOTALROWS: 'Total de linhas',
    HEADER_COLUMN_FAILEDROWS: 'Linhas não carregadas',
    STATUS_FAILED: 'Falha',
    STATUS_FAILED_ROWS: 'linhas não carregadas',

    STATUS_DONE: 'Completo',
    STATUS_PROCESSING: 'Processando',
    STATUS_COMPLETED: 'Completo',
    STATUS_PARTIALLY_SUCCESSFUL: 'failed rows',
    EMPTY_LIST: 'There are no downloads to be displayed.',
    HEADER_COLUMN_DOWNLOAD: 'Ação',
    HEADER_COLUMN_COUNTRY: 'País',
  },
  CsvTableView: {
    EMPTY_DOWNLOAD_LIST: 'There are no downloads to be displayed.',
    EMPTY_UPLOAD_LIST: 'There are no uploads to be displayed.',
  },
  UploadFileButtonView: {
    LABEL: 'Carregar arquivo',
  },
  UploadCsvModalView: {
    TITLE: 'Carregar arquivo',
    PRODUCTS_LIMIT_WARNING: 'Temos um limite de 2,000 promoções por vez.',
    DRAG_AND_DROP_MESSAGE: 'Arraste e solte o arquivo CSV aqui ou',
    SELECT_FILE_BUTTON: 'Selecionar arquivo',
    DESCRIPTION: 'Descrição do arquivo',
    BUTTON_CANCEL: 'Cancelar',
    BUTTON_UPLOAD: 'Upload',
    ONLY_CSV_SUPPORT: 'We only support CSV files.',
    COUNTRIES_LABEL: 'País',
  },
  PromotionFilters: {
    BUTTON: 'Filtros',
    FILTER: 'Filtros',
    PROMOTION_TYPE_FILTER: 'Tipo',
  },
  FilterChipList: {
    PROMOTION_TYPE_FILTER: 'Tipo',
  },
  DownloadCsvPlpView: {
    BUTTON_LABEL: 'BAIXAR CSV',
    DOWNLOADING_CSV_LABEL: 'Downloading', // tbd
  },
  Countries: {
    AR: 'Argentina',
    BR: 'Brasil',
    CA: 'Canadá',
    CO: 'Colômbia',
    DO: 'República Dominicana',
    EC: 'Equador',
    MX: 'México',
    PA: 'Panamá',
    PE: 'Peru',
    PY: 'Paraguai',
    SV: 'El Salvador',
    TZ: 'Tanzânia',
    UY: 'Uruguai',
    ZA: 'África do Sul',
    UG: 'Uganda',
  },

  PromotionTablePagination: {
    ROWS_PER_PAGE_LABEL: 'Rows per page', // TBD
  },
  ModalExportCsvView: {
    CANCEL_BUTTON: 'CANCELAR',
    DOWNLOAD_BUTTON: 'BAIXAR',
    MODAL_TITLE: 'Baixar',
    COUNT_PROMOTIONS: 'Promoções na fila',
    COUNT_PROMOTION: 'Promoção na fila',
    SELECT_FIELDS: 'Selecionar campos',
    MAX_PROMOTIONS_ERROR: 'Quantidade de mais de 2.000 promoções ultrapassada.',
  },
  PromotionsTable: {
    HEADER_COLUMN_TITLE: 'Título',
    HEADER_COLUMN_STATUS: 'Status',
    HEADER_COLUMN_RELEVANCE: 'Promoção em destaque',
  },
  ModalExportCsv: {
    DESCRIPTION: 'Descrição',
  },
  CheckboxAttributesView: {
    ALL_FIELDS: 'Todos os campos',
  },
};

export default ptBR;
