import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  box: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: '#FFFFFF',
    minHeight: '220px',
    height: 'auto',
    width: '396px',
    boxShadow: '0 1px 4px 0 rgba(0,0,0,0.5)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
  },
  closeButton: {
    marginBottom: '32px',
  },
  content: {
    padding: '32px 32px 0 32px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  titleStyle: {
    color: '#000000',
    fontFamily: 'Work Sans',
    fontSize: '21px',
    fontWeight: 600,
    letterSpacing: 0,
    lineHeight: '26px',
    marginBottom: '16px',
  },
  subtitleStyle: {
    fontFamily: 'Work Sans',
    fontSize: '16px',
    letterSpacing: 0,
    lineHeight: '20px',
    textAlign: 'center',
  },
  animatedImage: {
    margin: '32px 0 32px 0',
    display: 'flex',
    flexDirection: 'row',
    height: '42px',
  },
  dots: {
    marginTop: '32px',
    fontSize: '7px',
    position: 'relative',
  },
  dot: {
    width: '7px',
    height: '7px',
    borderRadius: '50%',
    background: '#262929',
    position: 'absolute',
    animation: '$fade 2s ease infinite',
    opacity: 0,
    '&:nth-child(1)': {
      left: 0,
    },
    '&:nth-child(2)': {
      left: '1.5em',
      animationDelay: '0.2s',
    },
    '&:nth-child(3)': {
      left: '3em',
      animationDelay: '0.5s',
    },
  },
  '@keyframes fade': {
    '0%': { opacity: 0 },
    '50%': { opacity: 1 },
    '100%': { opacity: 0 },
  },
});

export default useStyles;
