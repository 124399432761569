import MessageMap from '../i18n.d';

const es419: MessageMap = {
  Navigation: {
    PROMOTIONS: 'Promociones',
    BULKEDITOR: 'Editor de volumen',
  },
  PromotionPage: {
    PAGE_LAYOUT_TITLE: 'Promociones',
    NEW_PROMO: 'NUEVA PROMOCIÓN',
    ZONE_SELECTOR_COUNTRY_LABEL: 'País:',
  },
  SearchPromotionButton: {
    SEARCH: 'BUSCAR',
  },
  ItemsModalError: {
    ERROR_LOAD_ITEMS_TITLE: 'No pudimos cargar este combo',
    NO_LONGER_VALID: 'Parece que el ítem asociado a este combo ya no es válido.',
    PLEASE_CONTACT: 'Por favor, contacta tu',
    CONTACT_HREF: 'equipo de soporte.',
    CONFIRM_BUTTON: 'Entendido',
  },
  PromotionTable: {
    HEADER_COLUMN_TITLE: 'Título',
    HEADER_COLUMN_PROMOID: 'Promo ID',
    HEADER_COLUMN_STATUS: 'Status',
    HEADER_COLUMN_COMBOTYPE: 'Tipo',
    HEADER_COLUMN_ENDDATE: 'Fecha de finalización',
    TOOLTIP_COLUMN_PROMOID: 'El número identificador de la promoción',
    TOOLTIP_COLUMN_COMBOTYPE: 'Tipo de promoción',
    TOOLTIP_COLUMN_ENDDATE: 'La fecha en la que la promoción termina',
    EMPTY_TABLE_TITLE: 'No results so far',
    EMPTY_TABLE_DESCRIPTION:
      'Try changing the search terms or clearing your filters for more results.',
  },
  StatusTableCellView: {
    ENABLED: 'Conectado',
    DISABLED: 'Desconectado',
  },
  DownloadCsvPlpView: {
    BUTTON_LABEL: 'Descargar CSV',
  },
  PromotionFilters: {
    FILTER: 'Filtros',
  },
  FilterChipList: {
    PROMOTION_TYPE_FILTER: 'Tipo',
  },
  CreatePromotionRoute: {
    TITLE: 'Nuevo combo DT',
    ALERT_MESSAGE: 'To activate the combo, go to Membership and search: ID', // tbd
    COMBO_VENDOR_ID_COPY: 'Id copied!',
  },
  NewPromotionDiscardButtonView: {
    LABEL: 'Descartar',
  },
  NewPromotionCreateButtonView: {
    LABEL: 'Crear',
  },
  PromotionInformationCard: {
    COMBO_INFORMATION_CARD_TITLE: 'Información del combo',
    SUPPLIER_LABEL: 'Proveedor',
    COMBO_ID: 'ID',
    TITLE_LABEL: 'Título',
    TYPE_LABEL: 'Tipo',
    COMBO_TYPE: 'Digital Trade 3P',
    DESCRIPTION_LABEL: 'Descripción',
    FEATURED_LABEL: 'Promoción destacada',
    FEATURED_ON: 'On',
    FEATURED_OFF: 'Off',
    ITEMS_DAILY_LIMIT: 'Límite diario',
    ITEMS_MONTHLY_LIMIT: ' Límite mensual',
    COMBO_ID_TOOLTIP_MESSAGE: 'El número identificador de la promoción',
    COMBO_TYPE_TOOLTIP_MESSAGE: 'Tipo de promoción',
    COPY_URL_TOOLTIP: 'Combo Id copied!',
    EDIT: 'Edición',
    FEATURED_PROMOTION: 'Promoción destacada',
  },
  PromotionCardView: {
    QUANTITY: 'Cantidad',
  },
  PromotionCreatedDateView: {
    INVALID_DATE_ERROR: 'Fecha inválida.',
    MIN_START_DATE_ERROR: 'La fecha de inicio deberá ser posterior a la fecha de hoy',
    MAX_START_DATE_ERROR_WITH_END_DATE:
      'La fecha de inicio no podrá ser posterior a la fecha de finalización',
    MIN_END_DATE_ERROR_WITH_START_DATE:
      'Fecha de finalización no puede ser antes de la fecha de inicio',
    MIN_END_DATE_ERROR: 'Fecha de finalización deberá ser posterior a la fecha de hoy',
    START_DATE: 'Fecha de inicio',
    END_DATE: 'Fecha de finalización',
  },
  PromotionImageUploader: {
    IMAGE_FORMAT_NOT_SUPPORTED:
      'Image format not supported. Please, upload an image in .png or .jpeg formats only',
    IMAGE_SIZE_NOT_SUPPORTED:
      'Image size limit exceeded. Please, upload an image no larger than 1MB.',
    UPLOAD_MAIN_PROMOTION_IMAGE: 'Subir una imagen del combo.',
    THUMBNAILS_MUST_JPG_PNG_FORMAT:
      'La imagen deberá ser en formato JPG o PNG y pesar menos de 1MB.',
    EDIT_LABEL_BUTTON: 'Edición',
  },
  FormikInputTextView: {
    REQUIRED: 'Requerido',
  },
  DistributionCenterView: {
    TITLE: 'Centros de distribución',
    BUTTON_TITLE: 'Agregar',
  },
  DdcRowView: {
    BUTTON: 'Eliminar',
  },
  DdcListView: {
    NO_DDC: 'Los CDDs agregados se mostrarán aquí.',
    NO_DDC_MODAL: 'No hay DDC disponible para seleccionar',
    DDC_ID: 'ID',
    DDC_NAME: 'Nombre',
    DDC_STATUS: 'Status',
    DDC_ENABLED: 'Habilitado',
    DDC_DISABLED: 'Deshabilitado',
    DDC_SERVICE_MODEL: 'Modelo de Servicio',
  },
  ItemsView: {
    BUTTON_TITLE: 'Agregar',
    TITLE: 'Ítems',
    NO_ITEMS: 'Los ítems agregados se mostrarán aquí.',
  },
  AddItemModalView: {
    TITLE_HEADER: 'Ítems',
    LABEL_SUBMIT_BUTTON: 'Agregar',
    LABEL_CANCEL_BUTTON: 'Cancelar',
    NO_ITEM_AVAILABLE: 'Los ítems agregados se mostrarán aquí.',
  },
  NewPromoModalView: {
    PROMOTION_TYPE_TITLE: 'Elegir tipo de promoción',
    SUPPLIER_SELECTOR_TITLE: 'Seleccionar el proveedor',
    COUNTRY_SELECTOR_TITLE: 'Seleccionar el país',
    MODAL_TITLE: 'Nueva promoción',
    BUSINESS_MODEL: 'Modelo de servicio',
    BUSINESS_MODEL_DESCRIPTION: 'Selecciona el tipo de modelo de servicio que quieres crear.',
    CANCEL_BUTTON: 'Cancelar',
    SEND_BUTTON: 'Comienza',
    REQUIRED_FIELD: 'Este campo es obligatorio.',
    NO_DDC_MESSAGE:
      'No hay ningún centro de distribución disponible para ser seleccionado para su proveedor',
    NO_ITEMS_MESSAGE: 'No hay ningún producto disponible para ser seleccionado para su proveedor',
  },
  BusinessCards: {
    MODEL_1P3L: '1P3L',
    MODEL_1P3L_DESCRIPTION: 'Vendido por ABI y enviado por un proveedor.',
  },
  PromotionSearchView: {
    SEARCH_PROMOTION: 'Buscar por ID de promoción y título',
  },
  PromotionCards: {
    PROMOTION_TYPE_DTC: 'Digital Trade Combos',
    PROMOTION_TYPE_DTC_DESCRIPTION: 'Combos a ser canjeados por clientes utilizando puntos.',
  },
  UpdatePromotionStatusModalView: {
    ENABLED_STATUS_LABEL: 'Conectado',
    DISABLED_STATUS_LABEL: 'Desconectado',
    SAVE_BUTTON_LABEL: 'Guardar',
    CANCEL_BUTTON_LABEL: 'Cancelar',
  },
  PromotionDetailsRoute: {
    PAGE_BREADCRUMB_DETAILS: 'Detalles',
    PAGE_BREADCRUMB_PROMOTIONS: 'Promociones',
  },
  AddDistributionCenterModal: {
    TITLE: 'Centros de distribución',
    SUB_TITLE: 'Agregar los CDDs relacionados con el combo.',
    BUTTON_SUBMIT_TITLE: 'Agregar',
    BUTTON_CANCEL_TITLE: 'Cancelar',
    INPUT_PLACEHORDER: 'Buscar por nombre del CDD',
  },
  TableSearchModalView: {
    ITEM_SELECTED: 'ítem seleccionado.',
    ITEMS_SELECTED: 'ítems seleccionados.',
  },
  PromotionImageUploaderView: {
    TITLE: 'Imagen de la promoción',
    COPY_PICTURE_URL: 'Copiar el URL de la imagen',
    CANCEL_BUTTON: 'Cancelar',
    SAVE_BUTTON: 'Guardar',
    COPY_PICTURE_URL_TOOLTIP: 'URL de la imagen copiado',
  },
  EditPromotionInformationCard: {
    COMBO_INFORMATION_CARD_TITLE: 'Información del combo',
    SAVE: 'Guardar',
    CANCEL: 'Cancelar',
  },
  Countries: {
    AR: 'Argentina',
    BR: 'Brasil',
    CA: 'Canadá',
    CO: 'Colombia',
    DO: 'República Dominicana',
    EC: 'Ecuador',
    MX: 'México',
    PA: 'Panamá',
    PE: 'Perú',
    PY: 'Paraguay',
    SV: 'El Salvador',
    TZ: 'Tanzania',
    UY: 'Uruguay',
    ZA: 'Sudáfrica',
    UG: 'Uganda',
  },
  UploadListView: {
    TITLE: 'Reports',
  },
  BulkEditorPage: {
    TITLE: 'Editor de volumen',
    UPLOADS_TAB: 'Subidos',
    DOWNLOADS_TAB: 'Descargados',
    GET_UPLOADS_ERROR: 'Failed to Get Uploads Reports',
    GET_DOWNLOADS_ERROR: 'Failed to Get Downloads History',
    IMPORT_REQUEST_FAILED: 'Import Request Failed',
  },
  DownloadListView: {
    TITLE: 'Historial',
  },
  CSVTable: {
    FAILED_MESSAGE: 'Falló',
    HEADER_COLUMN_DATE: 'Fecha',
    HEADER_COLUMN_STATUS: 'Status',
    HEADER_COLUMN_DESCRIPTION: 'Descripción del error',
    HEADER_COLUMN_UPLOADDESCRIPTION: 'Descripción del archivo',
    HEADER_COLUMN_TOTALROWS: 'Total de filas',
    HEADER_COLUMN_FAILEDROWS: 'Filas falladas',
    STATUS_FAILED: 'Falló',
    STATUS_FAILED_ROWS: 'filas falladas',

    STATUS_DONE: 'Completos',
    STATUS_PROCESSING: 'Procesando',
    STATUS_COMPLETED: 'Completos',
    STATUS_PARTIALLY_SUCCESSFUL: 'failed rows',
    EMPTY_LIST: 'There are no downloads to be displayed.',
    HEADER_COLUMN_DOWNLOAD: 'Acción',
    HEADER_COLUMN_COUNTRY: 'País',
  },
  CsvTableView: {
    EMPTY_DOWNLOAD_LIST: 'There are no downloads to be displayed.',
    EMPTY_UPLOAD_LIST: 'There are no uploads to be displayed.',
  },
  UploadFileButtonView: {
    LABEL: 'Subir archivo',
  },
  UploadCsvModalView: {
    TITLE: 'Subir archivo',
    PRODUCTS_LIMIT_WARNING: 'Tenemos un límite de 2000 promociones por vez.',
    DRAG_AND_DROP_MESSAGE: 'Arrastra y suelta el archivo CSV aquí o',
    SELECT_FILE_BUTTON: 'Seleccionar archivo',
    DESCRIPTION: ' Descripción del archivo',
    BUTTON_CANCEL: 'Cancelar',
    BUTTON_UPLOAD: 'Subir',
    ONLY_CSV_SUPPORT: 'We only support CSV files.',
    COUNTRIES_LABEL: 'País',
  },
  ModalExportCsvView: {
    CANCEL_BUTTON: 'CANCELAR',
    DOWNLOAD_BUTTON: 'DESCARGAR',
    MODAL_TITLE: 'Descargar',
    COUNT_PROMOTIONS: 'Promociones en fila',
    COUNT_PROMOTION: 'Promocion en fila',
    SELECT_FIELDS: 'Seleccionar campos',
    MAX_PROMOTIONS_ERROR: 'Se excedió la cantidad de más de 2000 promociones.',
  },
  PromotionsTable: {
    HEADER_COLUMN_TITLE: 'Título',
    HEADER_COLUMN_STATUS: 'Status',
    HEADER_COLUMN_RELEVANCE: 'Promoción destacada',
  },
  ModalExportCsv: {
    DESCRIPTION: 'Descripción',
  },
  CheckboxAttributesView: {
    ALL_FIELDS: 'Todos los campos',
  },
};

export default es419;
