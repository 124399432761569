import { Box, Button } from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { formatMessage } from '../../i18n/formatters';
import Buzz from './Buzz';
import useStyles from './LoadingModalView.styles';

interface Props {
  title?: string;
  subtitle?: string | JSX.Element;
  hasCloseButton?: boolean;
  onClose: () => void;
  isOpen: boolean;
}

function LoadingModalView({
  title = formatMessage({ id: 'LoadingModalView.TITLE' }),
  subtitle = formatMessage({ id: 'LoadingModalView.SUBTITLE' }),
  hasCloseButton = false,
  onClose,
  isOpen,
}: Props): JSX.Element {
  const { box, content, titleStyle, subtitleStyle, animatedImage, dots, dot, closeButton } =
    useStyles();

  const renderCloseButton = () => {
    return hasCloseButton ? (
      <Button
        onClick={onClose}
        variant="contained"
        color="primary"
        data-test="loading-close-button"
        className={closeButton}
      >
        {formatMessage({ id: 'LoadingModalView.CLOSE_BUTTON' })}
      </Button>
    ) : null;
  };

  return (
    <Modal onClose={onClose} open={isOpen}>
      <Box data-test="loading-modal" className={box}>
        <div className={content}>
          <Typography className={titleStyle}>{title}</Typography>
          <Typography className={subtitleStyle}>{subtitle}</Typography>
          <div className={animatedImage}>
            <Buzz />
            <div className={dots}>
              <div className={dot} />
              <div className={dot} />
              <div className={dot} />
            </div>
          </div>
          {renderCloseButton()}
        </div>
      </Box>
    </Modal>
  );
}

export default LoadingModalView;
