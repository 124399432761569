import React from 'react';

function Buzz(): JSX.Element {
  return (
    <svg
      width="45px"
      height="42px"
      viewBox="0 0 45 42"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>B0D76D17-A70B-4C69-8A15-089AA75ECF21</title>
      <g id="02-Combos-CDP" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          id="Modal---Loading-State"
          transform="translate(-667.000000, -362.000000)"
          fillRule="nonzero"
        >
          <g id="Modal-/-Loading-Category-Creation" transform="translate(502.000000, 216.000000)">
            <g id="Mascot-/-Buzz-/-Side" transform="translate(165.000000, 146.000000)">
              <g id="ABI-Bees_Assets_Mascot-3_sRGB">
                <path
                  d="M21.595166,8.85941045 C29.8272122,1.11210117 30.0985979,0.027692284 33.243305,0.027692284 C35.7030148,0.027692284 39.2979142,1.88092954 39.2979142,6.25988388 C39.2979142,7.61315629 38.9515852,9.110508 38.070627,9.95461578 L21.595166,8.85941045 Z"
                  id="Path"
                  fill="#E1DCF1"
                />
                <path
                  d="M21.1320594,8.28996146 C32.081949,5.19467711 34.8316177,4.21618825 36.7015023,4.21618825 C40.5227014,4.21618825 44.6730584,7.48958011 44.6730584,12.336262 C44.6730584,15.5476377 42.3770871,19.1906007 39.9293372,19.9062917 L21.1320594,8.28996146 Z"
                  id="Path"
                  fill="#F0ECFC"
                />
                <path
                  d="M30.7206838,26.2266771 L30.8602869,25.9711638 L30.8602869,25.9711638 C30.1752389,34.090021 24.9377598,40.1919626 23.1179782,40.9233836 C21.2981967,41.6548045 13.295626,40.8743687 7.18431725,35.4871382 C14.1800787,37.8118989 27.0110824,32.7619886 30.7206838,26.2266771 Z M26.790735,11.5892969 L26.7910475,11.5900741 C27.7365264,12.8898543 28.6246301,14.5022075 29.4242343,16.4924447 C29.8102902,17.4533394 30.113878,18.4056512 30.3447931,19.3449117 L30.4746407,19.9070034 L30.4746407,19.9070034 L30.474514,19.9081333 C30.4376378,19.9930518 28.0561231,25.4141501 18.4516329,29.3081262 C8.82383944,33.1440905 3.35413132,30.8785947 3.26874842,30.8428093 C3.58160257,31.351841 3.91085087,31.8376753 4.25420739,32.30125 C3.91068246,31.8377714 3.58135008,31.3518234 3.26841902,30.8426657 L3.26732101,30.8421926 C2.66725892,29.8651906 2.12681028,28.8032278 1.6635517,27.6501542 C0.863947522,25.6599086 0.389649725,23.8812411 0.173029377,22.2885293 C0.321688993,22.3423418 5.27292581,24.1013226 14.82805,20.2895738 C24.1044734,16.5332189 26.5876484,11.9861857 26.7784747,11.6137878 L26.7894001,11.5920483 L26.7894001,11.5920483 L26.790735,11.5892969 Z M22.6516621,7.83935782 L22.6592215,7.85818823 C20.2502988,12.3965886 5.47737729,18.3518236 0.574191308,16.7347142 L0.574191308,16.7347142 L0.566631936,16.7158838 C-1.47402684,11.598125 2.77565777,6.48135457 8.37606279,4.2304061 C13.9764678,1.97945764 20.5843555,2.73230254 22.6516621,7.83935782 Z"
                  id="Combined-Shape"
                  fill="#000000"
                />
                <path
                  d="M4.48105207,11.3291094 C4.63087122,12.2949348 4.25388239,12.9468017 3.67796805,13.0361723 C3.10205372,13.1255428 2.54531231,12.6185662 2.39549316,11.6527408 C2.24567401,10.6869153 2.55952731,9.62798538 3.1354332,9.53861482 C3.71134754,9.44925272 4.33123293,10.3632924 4.48105207,11.3291094 Z"
                  id="Path"
                  fill="#FFFFFF"
                />
              </g>
              <path
                d="M18.4516329,29.3081262 C8.82383944,33.1440905 3.35413132,30.8785947 3.26874842,30.8428093 C4.39637866,32.6775287 5.73698893,34.2108854 7.1835402,35.4867918 C7.1835402,35.4867918 7.1835402,35.4867918 7.18463821,35.4872649 C14.2709286,37.842089 27.3453395,32.6295783 30.8605656,25.9713158 C30.8610386,25.9702176 30.8610386,25.9702176 30.8610386,25.9702176 C31.0226656,24.0479439 30.9297233,22.0131186 30.474514,19.9081333 C30.4376378,19.9930518 28.0561231,25.4141501 18.4516329,29.3081262 Z M26.7904224,11.5885112 L26.790735,11.5892969 C26.790735,11.5892969 24.5077962,16.3698992 14.82805,20.2895738 C5.1281512,24.1590763 0.172708421,22.2877605 0.172708421,22.2877605 L0.17239591,22.2869749 C-0.127521114,20.090431 0.0708131553,18.2708418 0.574191308,16.7347142 C5.47737729,18.3518236 20.2502988,12.3965886 22.6592215,7.85818823 C23.3728346,8.23872482 24.0790742,8.7232891 24.7710988,9.33556905 C25.4629714,9.94972444 26.1390411,10.6922375 26.7904224,11.5885112 Z"
                id="Shape"
                fill="#FFFF00"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Buzz;
